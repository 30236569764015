import { motion } from "framer-motion";
import { FADE_IN_ANIMATION_VARIANT } from "./constants";
import HeroImage from "../../assets/HeroImage.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const LandingMobile = ({
  navigate,
}: {
  navigate: (path: string) => void;
}) => {
  return (
    <motion.div
      className="hero-content"
      initial="initial"
      variants={FADE_IN_ANIMATION_VARIANT}
      animate="animate"
    >
      <h1>Create Your Own Reality</h1>
      <LazyLoadImage
        src={HeroImage}
        alt="city landscape"
        className="hero-image"
        effect="opacity"
      />
      <p>
        Societize uses the power of AI and data driven algorithms to create a
        reality in which you ae the only one who can make yourself succced.
      </p>
      <button onClick={() => navigate("/onboarding/signup")}>
        Get Started
      </button>
    </motion.div>
  );
};

export default LandingMobile;
